import React, {
    useState,
    useContext,
    useCallback,
  } from 'react'
import { navigate } from "gatsby"
import {
    Page,
    Layout,
    Card,
    Subheading,
    Icon,
    Stack,
    InlineError,
    Badge,
    Banner,
    Tooltip,
    Spinner
  } from "@shopify/polaris"
import { doc } from 'firebase/firestore';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import FirebaseContext from '../../../providers/firebase';
import styled from 'styled-components'
import CreateOfferSteps from './createOfferSteps'
import getCheckoutProfiles from '../../../helpers/getCheckoutProfiles';
import CustomizationSelectionCard from '../../../components/customizationSelectionCard'
const OfferTypeWrapper = styled.div`
    .offer-type-container {
      padding: 25px 0 40px;
      .nickname-container {
        text-align: center;
        max-width: 430px;
        margin: auto;
        margin-bottom: 35px;
        .nickname-title {
            font-weight: bold;
            display: block;
            padding-bottom: 10px;
        }
      }
      .offer-type {
        
        .offer-type-title {
            text-align: center;
            margin-bottom: 25px;
            h3 {
                color: #6D7175;
            }
        }
        .offer-type-option {
            height: 150px;
            width: 150px;
            padding: 20px 20px 5px;
            display: flex;
            flex-direction: column;
            justify-items: center;
            cursor: pointer;
            gap: 20px;
            .offer-type-option-container {
                display: flex;
                flex-direction: row;
                gap: 5px;
                justify-content: center;
                text-align: center;
                .Polaris-Icon {
                    margin-left: -27px;
                    margin-top: 0;
                }
                span {
                    color: #000;
                    font-weight: 600;
                    text-transform: uppercase;
                    font-size: 1.2rem;
                }
            }
            .offer-enabled {
                svg {
                    fill: #007F5F;
                }
            }
            .offer-type-edit {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                gap: 30px;
                button {
                    background: #95C9B4;
                    border-color: #202223;
                }
                .Polaris-Icon {
                    width: 50px;
                    height: 50px;
                }
            }
            .upsell-badge {
                position: absolute;
                bottom: 10px;
                .Polaris-Badge {
                    font-weight: 600;
                    text-transform: uppercase;
                    font-size: 1.2rem;
                }
            }
            .upsell-beta {
                left: 50%;
                -webkit-transform: translateX(-50%);
                transform: translateX(-50%)
            }
        }
        .active {
            .Polaris-Card {
                background: #008060;
            }
            .offer-type-option-container {
                span {
                    color:#fff;
                }
            }
            svg {
                fill: #fff;
            }
        }
      }
      .errors {
          padding: 5px 0;
      }
    }
`
function CreateOfferType(props) {
    const { location } = props
    const rules = location && location.state && location.state.rules
    const { firebase, shop, token, host } = useContext(FirebaseContext);
    const [error, setError] = useState(null)
    const [offerType, setOfferType] = useState(null)
    const [loading, setLoading] = useState(false)
    const shopDoc = doc(firebase.firestore, 'shops', shop);
    const [shopData, shopDataLoading, shopDataError] = useDocumentData(
      shopDoc,
        {
          transform: (snap) => snap.shopData
        }
      );

      const createOfferHandler = async () => {
        let checkoutProfiles
        try {
          setLoading(true)
          checkoutProfiles = await getCheckoutProfiles(token, shop, host)
          console.log("checkoutProfiles", checkoutProfiles)
        } catch (e) {
          console.log(e)
        }
        setLoading(false)
        if (checkoutProfiles && checkoutProfiles.data.checkoutProfiles) {
          /** Created a check for a published checkout profile and divided it into two options for the future
           * if we need to use different behavior */
          if (
            !checkoutProfiles.data.checkoutProfiles.edges.some(
              ({ node }) => node.isPublished
            )
          ) {
            navigate("/app/offers/createCheckoutType", {
              state: { rules },
              replace: true,
            })
          } else {
            navigate("/app/offers/createCheckout", {
              state: { rules },
              replace: true,
            })
          }
        } else {
          navigate("/app/offers/create", {
            state: { rules },
            replace: true,
          })
        }
      }
    
      const createCartOfferHandler = useCallback(() => {
        navigate("/app/offers/createCartType", {
          state: { rules },
          replace: true,
        })
      }, [rules])
    
      const createPostPurchaseOfferHandler = useCallback(() => {
        navigate("/app/offers/createPostPurchase", {
          state: { rules },
          replace: true,
        })
      }, [rules]) 

    const allCheckout = ["shopify_plus","affiliate","partner_test","plus_partner_sandbox","staff","staff_business","custom","unlimited"]
    
    const REVENUE_SELECTION = [
        {
          title: "Cart upsell",
          content: "Cart drawer, cart page or a popup",
          image:
            "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/cart-onboarding.png?alt=media&token=bcee7290-bf57-462b-b053-b96f5f016805",
          url: createCartOfferHandler,
        },
        {
          title: "Checkout upsell",
          content: "Increase AOV with upsells and cross-sells",
          image:
            "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/checkout-upsell-onboarding.png?alt=media&token=4587b28d-ba56-4d86-8c84-a0279b2195cb",
          url: createOfferHandler,
          grey:
            shopData &&
            shopData.plan_name &&
            allCheckout.indexOf(shopData.plan_name) === -1,
          loading: loading,
          plusOnly: true,
        },
        {
          title: "Post-purchase upsell",
          content: "Boost AOV after the purchase",
          image:
            "https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/postpurchase-onboarding.png?alt=media&token=6019fd9e-2c57-46ab-89f5-a2a836a3be34",
          url: createPostPurchaseOfferHandler,
        },
      ]
    return (
        <Page 
      breadcrumbs={[{content: 'Offers', url: '/app/offers'}]}
      title={
        `Create new`
      }
      //primaryAction={{content: 'Save'}}
    >
        <Layout>
            <Layout.Section>
                <Card>
                <Card.Section>
                <CreateOfferSteps step={1}/>
                <OfferTypeWrapper>
                    <div className='offer-type-container'>
                        <div className='offer-type'>
                            <div className='offer-type-title'>
                                <Subheading>
                                Which page do you want to customize?
                                </Subheading>
                            </div>
                            <Stack distribution="center">
                            {
                               REVENUE_SELECTION.map((item, index) => {
                                return <CustomizationSelectionCard key={index} {...item} />
                                })
                            }
                            </Stack>
                            
                            {
                                error && error.type ?
                                <div className='errors'>
                                    <InlineError message={error.type} />
                                </div>
                                :
                                false
                            }
                        </div>
                    </div>
                    {"Post Purchase" === offerType ?
                        <div style={{margin: '0 100px 80px 100px'}}>
                            
                            <Banner
                                title="Enable post-purchase offers for your store"
                                status="warning"
                                action={{
                                    content: 'Got to checkout settings', 
                                    onAction: () => {
                                        window.open(`https://${shopData.domain}/admin/settings/checkout#additionalscripts`, '_blank')
                                    },
                                }}
                                >
                                 <p>Go to your Shopify store settings → click ‘Checkout’ in the sidebar → scroll down to ‘Post-purchase page settings’ → Select UpsellPlus → Save changes</p>
                            </Banner>
                        </div>
                        :
                        false
                    }
                    
                </OfferTypeWrapper>
                
                </Card.Section>
                </Card>
            </Layout.Section>
        </Layout>
    </Page>
   
    )
}

export default CreateOfferType;